<style scoped lang="less">
    .product{
		
		.item{
			display: flex;
			margin-top: 5px;
			background: #fff;
			
			&:first-child{
				margin-top: 0;
			}
			
			.img{
				position: relative;
				flex: 0 0 auto;
				width: 140px;
				height: 140px;
				overflow: hidden;
				
				>img{
					display: block;
					width: 100%;
				}
				.time{
					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 24px;
					line-height: 24px;
					background: #ff547b;
					font-size: 12px;
					color: #fff;
					text-align: center;
				}
			}
			
			.right{
				flex: 1 1 auto;
				overflow: hidden;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				margin-left: 10px;
				margin-right: 10px;
				
				.top{
					overflow: hidden;
				}
				
				.name{
					font-size: 16px;
					
					overflow: hidden;
					max-height: 42px;
					line-height: 21px;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					color: #333333;
					font-weight: bold;
					
					.tag{
						display: inline-block;
						margin-right: 5px;
						vertical-align: 2px;
						padding: 0 10px;
						height: 16px;
						line-height: 16px;
						color: #fff;
						background: #ff547b;
						border-radius: 8px;
						font-size: 12px;
						text-align: center;
					}
					
					.name-box{
						display: inline;
					}
				}
				.maidian{
					font-size: 12px;
					color: #999;
					margin-top: 4px;
					height: 30px;
					line-height: 15px;
					display: -webkit-box; 
					-webkit-box-orient:vertical; 
					-webkit-line-clamp: 2; 
					overflow: hidden;
				}
				
				.sell-count{
					.progress{
						position: relative;
						height: 6px;
						background: #f7f7f7;
						border-radius: 6px;
						
						.current{
							position: absolute;
							left: 0;
							top: 0;
							height: 100%;
							border-radius: 6px;
							background: #ff547b;
						}
					}
					.tit{
						font-size: 12px;
						margin-top: 5px;
					}
				}
				.bottom-button{
					font-size: 14px;
					.bt-left{
						background: #FFE7E7FF;
						padding:5px;
						width: 100%;
						border-radius: 2px 0px 0px 2px;
						flex:1;
						height: 40px;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: flex-start;
					}
					.bt-right{
						background: #FE2F25FF;
						position: relative;
						width: 60px;
						height: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						bottom: 0;
						right: 0;
						border-radius: 0px 2px 2px 0px;
						.sellCount{
							position: absolute;
							top: -21px;
							color: #DC420EFF;
							font-size: 9px;
							z-index: 2;
						}
						.bt-right-img{
							position: absolute;
							top: -23px;
							z-index: 1;
						}
					}
				}
				
				.price{
					display: flex;
					margin-top: -14px;
					
					.left{
						font-size: 15px;
						color: #ff547b;
						flex: 1 1 auto;
						margin-right: 10px;
						white-space: nowrap;
						display: flex;
						align-items: center;
						
						.old{
							font-size: 12px;
							color: #999;
							padding-top: 2px;
							margin-left: 5px;
							text-decoration: line-through;
						}
					}
					.btn{
						width: 70px;
						height: 26px;
						line-height: 26px;
						text-align: center;
						font-size: 12px;
						color: #ff547b;
						border: 1px solid #ff547b;
						border-radius: 16px;
						margin-bottom: 4px;
					}
				}
			}
		}
		.shouqing {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, .4);
			display: flex;
			justify-content: center;
			align-items: center;
		
			img {
				display: block;
				width: 50%;
				transform: rotate(-30deg);
			}
		}
    }
    .haveSpace{
        margin-bottom: 10px;
    }
	
	.tabs{
		height: 50px;
		background: #ff547b;
		display: flex;
		justify-content: space-around;
		padding: 0 10px;
		
		.tab{
			flex: 1 1 auto;
			max-width: 120px;
		}
	}
	
	.tabsScroll{
		height: 50px;
		background: #ff547b;
		overflow: hidden;
		white-space: nowrap;
		width: 100%;
		padding-left: 10px;
		
		.tab{
			display: inline-block;
			width: 100px;
		}
	}
	
	.tab{
		font-size: 14px;
		color: #fff;
		text-align: center;
		margin-top: 5px;
		padding-top: 5px;
		border-radius: 6px 6px 0 0;
		height: 45px;
	}
	.tabCurrent{
		background-color: #fff;
		color: #ff547b;
	}
	
	.time-wraper{
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 40px;
		color: #666;
		font-size: 14px;
		padding: 0 10px;
		background-color: #fff;
	}
	
	.mulitProduct{
		background-color: #fff;
		padding-bottom: 10px;
	}
	.mulitItem{
		margin: 10px;
		border-radius: 4px;
		box-shadow: 0 0 12px 1px rgba(34, 24, 21, 0.1);
	}
	.tag2{
		display: inline-block;
		margin-right: 5px;
		vertical-align: 2px;
		padding: 0 10px;
		height: 16px;
		line-height: 16px;
		color: #fff;
		background: #FF2E2EFF;
		border-radius: 2px;
		font-size: 12px;
		text-align: center;
		
	}
	
</style>
 
<template>
    <div class="singleRowProduct-module" @mouseenter="data.visible=true" @mouseleave="data.visible=false" style="position: relative;padding-left: 40px;padding-right: 45px;"  :class="{haveSpace: !data.hideSpace}">
        <module-name :name="name"></module-name>
        <controler @edit="edit();data.editVisible=true" @moveUp="$emit('moveUp')" :visible="data.visible" @moveDown="$emit('moveDown')" @remove="$emit('remove')" ></controler>
        
		<div :class="{tabsScroll: data.activitys.length > 4, tabs:data.activitys.length <= 4}" v-if="data.type == 2 && data.activitys.length"
			 :style="{background:data.style==1?'#ff547b':'#FE2F25FF'}">
			<div class="tab" :style="{color:index==0?(data.style==1?'#ff547b':'#FE2F25FF'):'white'}" :class="{tabCurrent: index === 0}"
			 v-for="(item, index) in data.activitys" :key="item.Id">
				<div>{{item.timeStr}}</div>
				<div style="font-size: 12px;margin-top: 3px;">{{item.dateStr}}</div>
			</div>
		</div>
		<div class="time-wraper" v-if="data.type==2 && (state === 0 || state === 1)">
			<div class="left" v-if="state === 0">即将开抢 先抢先得哦~</div>
			<div class="left" v-if="state === 1">抢购中，先抢先得哦~</div>
			<div class="right">
				<span v-if="state === 0" style="padding-right: 3px;">距开始</span>
				<span v-if="state === 1" style="padding-right: 3px;">距结束</span>
				<span v-if="data.activityData.days">{{data.activityData.days}}天</span>{{data.activityData.hours}}:{{data.activityData.minutes}}:{{data.activityData.seconds}}
			</div>
		</div>
		<div v-if="data.style==1">
			<div class="product" v-if="!list.length">
					<div class="item">
					<div class="img">
						<img :src="normalData.url" >
						<div class="time" v-if="data.type!=2">
							距结束：6天18:30:05
						</div>
						
					</div>
					<div class="right">
						<div class="top">
							<div class="name">{{normalData.name}}</div>
							<div class="maidian">{{normalData.point}}</div>
						</div>
						
						<div class="sell-count">
							<!-- <div class="progress"><div class="current" style="width: 40%;"></div></div> -->
							<div class="tit">剩余<span style="color: #ff547b;">15</span>件</div>
						</div>
						<div class="price">
							<div class="left">
								&yen;99.99
								<div class="old">&yen;299.99</div>
							</div>
							<div class="btn">立即抢购</div>
						</div>
					</div>
				</div>
			</div>
			<div class="product" :class="{mulitProduct: data.type==2}" v-else>
				<div class="item" :class="{mulitItem: data.type==2}" v-for="(item, index) in list" :key="item.ProductId" v-if="index<18">
					<div class="img">
						<img :src="item.ImgUrlComplete" >
						<div class="time" v-if="data.type!=2 && state === 0">
							距开始：<span v-if="data.activityData.days">{{data.activityData.days}}天</span>{{data.activityData.hours}}:{{data.activityData.minutes}}:{{data.activityData.seconds}}
						</div>
						<div class="time" v-if="data.type!=2 && state === 1">
							距结束：<span v-if="data.activityData.days">{{data.activityData.days}}天</span>{{data.activityData.hours}}:{{data.activityData.minutes}}:{{data.activityData.seconds}}
						</div>
						<div class="shouqing" v-if="item.Stock <= 0">
							<img src="https://cdn.dkycn.cn/images/melyshop/shouqing.png" mode="widthFix"/>
						</div>
					</div>
					<div class="right">
						<div class="top">
							<div class="name"><div class="tag">{{item.DiscountText}}</div><div class="name-box">{{item.ProductName}}</div></div>
							<div class="maidian">{{item.SellPoint}}</div>
						</div>
						
						<div class="sell-count">
							<!-- <div class="progress"><div class="current" style="width: 40%;"></div></div> -->
							<div class="tit">剩余
								<span style="color: #ff547b;">{{item.Stock}}</span>
								件
							</div>
						</div>
						<div class="price">
							<div class="left">
								&yen;{{item.MinDiscountPrice}}
								<div class="old" v-if="item.OriginPrice">&yen;{{item.OriginPrice}}</div>
							</div>
							<div class="btn" v-if="state === 2" style="background: #999;color:#fff;border:none">已结束</div>
							<div class="btn" v-if="state === 1 && item.Stock>0">立即抢购</div>
							<div class="btn" v-if="state === 0">提醒我</div>
							<div class="btn" v-if="(state === 1 && item.Stock<=0)">去看看</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else-if="data.style==2">
			<div class="product" style="margin:11px 10px" v-if="!list.length">
				<div class="item" style="padding:5px">
					<div class="img">
						<img :src="normalData.url" >
					</div>
					<div class="right">
						<div class="top">
							<div class="name">{{normalData.name}}</div>
							<div class="maidian">{{normalData.point}}</div>
						</div>
						<div class="bottom-button flex flex-align-end">
							<div class="bt-left">
								<div style="font-size:12px;margin-top:5px;color:#333333" v-if="data.type==1">距结束：4天06:26:31</div>
								<div class="flex flex-align-center">
									<img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/Lightning-icon.png" style="width:12px;height:12px" alt="">
									<span class="font-12 margin-left-5" style="color:#FE2F25FF;margin-top:3px">限时价：￥</span>
									<span class="font-18" style="color:#FE2F25FF">26</span>
								</div>
							</div>
							<div class="bt-right">
								<img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/qiang-icon.png" style="width:25px;height:20px" alt="">
								<div class="sellCount">
									已售999
								</div>
								<img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/sellCount-angle.png" class="bt-right-img" style="width:50px;" alt="">
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="product" style="margin:0px 10px;padding:10px 0px" v-else>
				<div class="item" style="padding:5px" :style="{'border-radius':data.type==2?'0px':'4px'}" :class="{mulitItem: data.type==2}" v-for="(item, index) in list" :key="item.ProductId" v-if="index<18">
					<div class="img">
						<img :src="item.ImgUrlComplete" >
						<div class="shouqing" v-if="item.Stock <= 0">
							<img src="https://cdn.dkycn.cn/images/melyshop/shouqing.png" mode="widthFix"/>
						</div>
					</div>					
					<div class="right">
						<div class="top">
							<div class="name"><div class="tag2">{{item.DiscountText}}</div><div class="name-box">{{item.ProductName}}</div></div>
							<div class="maidian">{{item.SellPoint}}</div>
						</div>
						<div class="bottom-button flex flex-align-end">
							<div class="bt-left" :style="{background:state==2?'#F9F9F9FF':'#FFE7E7FF'}">
								<div style="font-size:12px;margin-top:5px;color:#333333;white-space:nowrap" v-if="data.type!=2 && state === 0">
									距开始：<span v-if="data.activityData.days">{{data.activityData.days}}天</span>{{data.activityData.hours}}:{{data.activityData.minutes}}:{{data.activityData.seconds}}
								</div>
								<div style="font-size:12px;margin-top:5px;color:#333333;white-space:nowrap" v-if="data.type!=2 && state === 1">
									距结束：<span v-if="data.activityData.days">{{data.activityData.days}}天</span>{{data.activityData.hours}}:{{data.activityData.minutes}}:{{data.activityData.seconds}}
								</div>
								<div class="flex flex-align-center" :style="{background:state==2?'#F9F9F9FF':'#FFE7E7FF'}">
									<img v-if="state==2" src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/Lightning-icon2.png"
									 style="width:12px;height:12px;vertical-align: bottom;" alt="">
									<img v-else src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/Lightning-icon.png"
									 style="width:12px;height:12px;vertical-align: bottom;" alt="">
									<div class="font-12 margin-left-5" style="white-space:nowrap;margin-top:3px"
									 :style="{color:state==2?'#C2C2C2FF':'#FE2F25FF'}">限时价：￥</div>
									<div class="font-18" style="white-space:nowrap"
									 :style="{color:state==2?'#C2C2C2FF':'#FE2F25FF'}">{{item.ProductPrice}}</div>
								</div>
							</div>
							<div class="bt-right" :style="{background:state==2?'#C2C2C2FF':'#FE2F25FF'}">
								<div v-if="state==0" style="font-size:16px;color:white" class="font-bold">预约</div>
								<img v-else-if="(state==1&&item.Stock>0)||state==2" src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/qiang-icon.png" style="width:25px;height:20px" alt="">
								<div v-else-if="state==1&&item.Stock<=0" style="font-size:16px;color:white" class="font-bold">去看看</div>
								<div class="sellCount" v-if="item.VirtualSales">
									已售
									<span v-if="item.VirtualSales<10000">{{item.VirtualSales}}件</span>
									<span v-if="item.VirtualSales>=10000">{{Math.floor(item.item.VirtualSales/10000)}}万件+</span>
								</div>
								<img v-if="item.VirtualSales" src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/sellCount-angle.png" class="bt-right-img" style="width:50px;" alt="">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="cover" v-if="data.visible" style="position: absolute;left:40px;top:0;right: 45px;height: 100%;border: 3px solid #0000FF;"></div>

    </div>
</template>

<script>

import moduleName from '../../moduleName';
import controler from '../../controler';
import edit from './edit';



export default {
    name: 'normal-module',
    components: {
        moduleName,
        controler,
        edit,
        
    },
    props: {
        name: {
            type: String,
            default: ''
        },
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        
    },
	computed:{
		list(){
			if (this.data.type!=2){
				return this.data.list;
			}else{
				if (this.data.activitys.length){
					return this.data.activitys[0].list
				}else{
					return []
				}
			}
		},
		state(){
			
			if (this.data.type!=2){
				return this.data.activityData.ActivityState;
			}else{
				if (this.data.activitys.length){
					console.log(this.data.activitys[0].ActivityState)
					return this.data.activitys[0].ActivityState
				}
			}
			return 2
		}
	},
    data () {
        return {
            
            btnType: 1, //按钮三种样式
            normalData: {
                url: this.$store.state.pageManageConfig.staticImage.noPic,
                name: '此处是商品名称',
								point: '此处显示卖点',
                price: 99.00,
                marketPrice: 100.00,
            }
        };
    },
    methods: {
        edit(){
            this.$emit('visibleChange', this.data.visible)
        },
        
        close(){
            this.$emit('visibleChange', false)
        }

    },
    mounted () {
		console.log(this.data, '限时折扣')
    },
    watch: {
        
    }
};
</script>

